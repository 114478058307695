
<template>
  <div />
</template>
<script>
export default {
  created () {
    this.$router.push('/dashboard');
  },
};
</script>
